<template>
  <div>
    <v-hover v-if="elevated" v-slot="{ hover }">
      <v-card :elevation="hover ? 4 : 2" :class="{ 'pa-4 mt-4 grey lighten-4': true, 'lighten-2': hover }" @click="$emit('click')">
        <h3>{{title}}</h3>
        <p class="mb-0">{{text}}</p>
      </v-card>
    </v-hover>
    <v-list-item v-else link three-line @click="$emit('click')" >
        <v-list-item-icon v-if="icon"><v-icon color="grey" size="50" class="mt-1" style="min-width:60px">{{icon}}</v-icon></v-list-item-icon>
        <v-list-item-content>
        <v-list-item-title>{{title}}</v-list-item-title>
        <v-list-item-subtitle>{{text}}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
  </div>
  <!--<v-sheet :elevation="1" class="text-center align-center pa-4" @click="$emit('click')">
    <v-icon v-if="icon" size="50" color="grey">{{icon}}</v-icon>
    <h3 class="my-4">{{title}}</h3>
    <p>{{text}}</p>
  </v-sheet>-->
</template>
 
<script>

export default {
  name: "BigButton",
  components: {
  },
  props: {
    icon: String,
    title: String,
    text: String,
    elevated: Boolean,
  },
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
      
  },
  watch: {
    
  }
};
</script>

<style lang="scss">

</style>

        